import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import { Logo } from '@components/ui'
import { Searchbar, UserNav } from '@components/common'
import HamburgerMenu from '@components/common/HamburgerMenu'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import { MENU_TYPE, TABLET_WIDTH } from '@constants/common'
import { useCurrentWidth } from '@lib/hooks/useCurrentWidth'
import { useStoreDataContext } from '@contexts/storeDataContext'
import { NavbarRoot } from './NavbarRoot'
import s from './Navbar.module.css'
import CopperStudioSearchBar from '../CopperStudioSearchBar'

const Navbar: FC = () => {
  const { menu_type: menuType } = useBrandThemeContext()
  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()
  const currentWidth = useCurrentWidth()
  const [isScrolledDown, setIsScrolledDown] = useState(false)
  const [searchBarVisible, setSearchBarVisible] = useState(false)

  const isShowHamburgerMenu =
    (menuType && menuType === MENU_TYPE.SIDE_MENU_ONLY) ||
    currentWidth < TABLET_WIDTH ||
    (isScrolledDown && menuType && menuType !== MENU_TYPE.UPPER_MENU_ONLY)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const position = window.scrollY

    setIsScrolledDown(position >= 100)
  }

  return (
    <NavbarRoot>
      <div
        className={cn(s.nav, {
          'md:pl-[100px] md:pr-[75px] md:py-[20px] px-[20px]': isHomeBrand,
        })}
      >
        <div className={s.menuWrap}>
          <div className={cn({ 'pl-[20px] flex items-center': isHomeBrand })}>
            <HamburgerMenu
              isHomeBrand={isHomeBrand}
              isShowHamburgerMenu={Boolean(isShowHamburgerMenu)}
            />
          </div>
          {!isHomeBrand ? (
            <Link href='/'>
              <a aria-label='Logo' className={s.logoLink} title='DirectNine'>
                <Logo className={s.logo} />
              </a>
            </Link>
          ) : null}
        </div>
        {process.env.COMMERCE_SEARCH_ENABLED && !isHomeBrand && (
          <div className={s.searchbarWrapper}>
            <Searchbar />
          </div>
        )}

        {isHomeBrand && (
          <Link href='/'>
            <a
              aria-label='Logo'
              className={cn(s.logoLink, {
                'md:!block': isHomeBrand,
              })}
              title='DirectNine'
            >
              <Logo className='w-[100px] h-[40px] md:!w-[200px] md:!max-w-[200px] md:!h-[80px]' />
            </a>
          </Link>
        )}
        <div className='inline-flex flex-1 justify-end'>
          <UserNav
            searchBarVisible={searchBarVisible}
            setSearchBarVisible={setSearchBarVisible}
          />
        </div>
      </div>
      {isHomeBrand && searchBarVisible && (
        <div
          className={cn(
            'flex justify-center w-full pt-4 pb-4 md:!hidden',
            s.item,
            {
              '!hidden': !searchBarVisible,
            },
          )}
        >
          <li
            className={cn(s.item, {
              '!hidden': !searchBarVisible,
            })}
          >
            <CopperStudioSearchBar
              searchBarVisible={searchBarVisible}
              setSearchBarVisible={setSearchBarVisible}
            />
          </li>
        </div>
      )}
      {process.env.COMMERCE_SEARCH_ENABLED && !isHomeBrand && (
        <div className='flex py-4 lg:px-6 w-full lg:hidden'>
          <Searchbar id='mobile-search' />
        </div>
      )}
    </NavbarRoot>
  )
}

export default Navbar
