import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { useStoreDataContext } from '@contexts/storeDataContext'
import s from './Navbar.module.css'

interface INavbarRootProps {
  children: ReactNode
}

export const NavbarRoot: FC<INavbarRootProps> = ({ children }) => {
  // return <div className={cn(s.root, { '': hasScrolled, [s.rootNone]: isException })}>{children}</div>

  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()

  return <div className={cn(s.root, { '!p-0': isHomeBrand })}>{children}</div>
}
