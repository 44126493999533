import { createContext, FC, useContext, useEffect, useState } from 'react'
import { NormalizedMenuTaxon } from '@models'
import { getTaxons } from 'api/getTaxons'
import { normalizeTaxonsForMenu } from '@components/common/UserNav/MenuSidebarView/normalizeTaxonsForMenu'

export const TaxonsContext = createContext({} as NormalizedMenuTaxon[])

TaxonsContext.displayName = 'TaxonsContext'

export const TaxonsProvider: FC = props => {
  const [value, setValue] = useState([] as NormalizedMenuTaxon[])

  useEffect(() => {
    getTaxons().then(res => {
      setValue(normalizeTaxonsForMenu(res))
      console.log(normalizeTaxonsForMenu(res)[0])
    })
  }, [])

  return <TaxonsContext.Provider value={value} {...props} />
}

export const useTaxonsContext = () => {
  const context = useContext<NormalizedMenuTaxon[]>(TaxonsContext)

  if (context === undefined) {
    throw new Error(
      'useStoreDataContext must be used within a StoreDataProvider',
    )
  }

  return context
}
