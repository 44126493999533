import axios from 'axios'
import { requireConfigValue } from '@framework/isomorphic-config'

export type Taxon = {
  attributes: {
    depth: number,
    description: string,
    is_child: boolean,
    is_leaf: boolean,
    is_root: boolean,
    left: number,
    meta_description: string,
    meta_keywords: string,
    meta_title: string,
    name: string,
    original_url: string,
    permalink: string,
    position: number,
    pretty_name: string,
    public_metadata: { [key: string]: any },
    right: number,
    seo_title: string,
    updated_at: string,
  },
  id: string,
  relationships: {
    children: {
      data: {
        id: string,
        type: string,
      }[],
    },
    image: { data: { [key: string]: any } },
    parent: { data: { [key: string]: any } },
    taxonomy: { data: { [key: string]: any } },
  },
  type: string,
}
export type GetTaxonsProps = {
  data: Taxon[],
  included: Taxon[],
  links: {
    self: string,
    next: string,
    prev: string,
    last: string,
    first: string,
  },
  meta: { count: number, total_count: number, total_pages: number },
}

export const getTaxons = async (depth?: number | number[]): Promise<GetTaxonsProps> => {
  const apiUrl = requireConfigValue('apiHost')

  const { data: res } = await axios.get(`${apiUrl}/api/v2/storefront/taxons`, {
    params: {
      'filter[depth]': depth || 1,
      'filter[with_stock]': true,
      include: 'children,image',
    },
  })

  return res
}
