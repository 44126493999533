import axios from 'axios'
import { requireConfigValue } from '@framework/isomorphic-config'
import { AllCmsPages } from '@models'


export const getAllCmsPages = async (): Promise<AllCmsPages> => {
  const apiUrl = requireConfigValue('apiHost')

  const { data: res } = await axios.get(
    `${apiUrl}/api/v2/storefront/cms_pages`,
    {
      headers: {
        'Cache-Control': 'max-age=600, must-revalidate',
      }
    },
  )

  return {
    data: res.data,
  }
}
