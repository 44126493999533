import axios from 'axios'
import { requireConfigValue } from '@framework/isomorphic-config'


type PostSubscriptionProps = {
  email: string,
}

export const postSubscription = ({ email }: PostSubscriptionProps) => {
  const apiUrl = requireConfigValue('apiHost')
  return axios.post(`${apiUrl}/api/custom/subscription`, {
    email,
  })
}