import { FC } from 'react'
import { useTaxonsContext } from '@contexts/taxonsContext'
import MenuItem from './MenuItem'
import s from './TopMenu.module.css'

export const TopMenu: FC = () => {
  const taxons = useTaxonsContext()

  return (
    <nav className={s.root}>
      <ul className={s.linksWrapper}>
        {taxons.map(taxon => (
          <MenuItem key={taxon.id} item={taxon} />
        ))}
      </ul>
    </nav>
  )
}
